.rwd002-mainImg {
  display: flex;
  justify-content: center;
  margin-bottom: map-get($spacers, 6);
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 4);
  }
  &__inner {
    img {
      max-width: 100%;
      height: auto;
    }
    > img[width="100%"] {
      width: 100vw;
      height: 100%;
      object-fit: cover;
      max-height: calc(100vh - 120px);
      @include media-down(md) {
        max-height: calc(100vh - 55px);
      }
    }
  }
}