.is--home .rwd002-container{
  margin-top: 0;
  @include media-down(lg) {
    margin-top: 0;
  }
}

.mainvisual {
  position: relative;
  height: 200vh;
  height: 200dvh;

  .swiper-slide-active img,
  .swiper-slide-duplicate-active img,
  .swiper-slide-prev img {
    animation: zoomUp 10s linear 0s 1 normal both;
  }

  &__slider {
    // position: sticky;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &__slider-item {
    height: 100vh;
    height: 100dvh;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:after{
      content: '';
      position: absolute;
      pointer-events: none;
      top: -150px;
      bottom: 0;
      left: 0;
      width: 100%;
      transition: all .5s ease-in-out;
    }
    .is-dark &:after {
      background-color: fade-out( $black, 0.5 );
    }
  }

  &__overlay {
    position: absolute;
    top: -150px;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    padding-top: 150px;
  }

  &__lead {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    height: 100dvh;
    padding: map-get($spacers, 2);
    color: $white;
    text-align: center;

    &.is-primary {
      padding-bottom: map-get($spacers, 5 );
      @include media-down(lg) {
        padding-bottom: map-get($spacers, 5 );
      }
    }

    .lemoncake-logo {
      @include media-down(lg) {
        max-width: 100%;
        height: auto;
      }
    }

    strong,
    span {
      display: block;
      font-size: min( 8.53vw, calc(36 / 16 * $font-size-base));
      font-weight: $font-weight-bold;
      letter-spacing: 0.2em;
      font-feature-settings: "palt";
    }
    span{
      font-size: min( 4vw, calc(18 / 16 * $font-size-base));
      margin-top: 0.5em;
    }
  }
}

.card-topics {
  position: absolute;
  bottom: 100vh;
  bottom: 100dvh;
  width: 372px;
  max-width: 75vw;
  right: 0;
  z-index: 1;
  margin-bottom: map-get($spacers, 5);
  background-color: $white;
  border-radius: 5px 0 0 5px;
  padding: 5px 0 5px 5px;
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 3);
  }
  &__item{
    display: flex;
    align-items: center;
    &:hover,&:focus{
      text-decoration: none;
      .title {
        text-decoration: underline;
        text-underline-offset: 0.2em;
      }
    }
  }
  &__item:hover &__img,&__item:focus &__img{
    transform: scale(1.1);
  }
  &__thumb{
    width: min(31vw, 135px);
    height: auto;
    aspect-ratio: 1;
    flex-shrink: 0;
    overflow: hidden;
  }
  &__img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: $transition-base;
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0.9em;
  }
  &__title {
    font-family: 'Roboto Condensed', $font-family-base;
    font-size: min(4.26vw, calc(19 / 16 * $font-size-base));
    font-weight: $font-weight-normal;
    letter-spacing: 0.2em;
    color: $secondary;
    margin-bottom: 0.5em;
  }
  .date {
    font-family: 'Jost', $font-family-base;
    font-size: min( 3.2vw, calc(13 / 16 * $font-size-base));
    letter-spacing: 0.2em;
    font-weight: $font-weight-bold;
    margin-bottom: 0.4em;
  }

  .title {
    font-size: min( 3.46vw, calc(14 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    letter-spacing: 0.1em;
  }
}

.block-story {
  background: top -5px center / 962px auto no-repeat, bottom center / 1012px auto no-repeat, $secondary;
  background-image: url('/assets/img/home/story_bg_head@2x.png'), url('/assets/img/home/story_bg_foot@2x.png');
  color: $white;
  padding: map-get($spacers, 6) map-get($spacers, 3);
  margin-bottom: map-get($spacers, 6 );
  text-align: center;
  @include media-down(md) {
    padding: map-get($spacers, 4) 2rem 27vw;
    margin-bottom: map-get($spacers, 5 );
    background: top center / contain no-repeat, bottom center / contain no-repeat, $secondary;
    background-image: url('/assets/img/home/story_bg_head_sp@2x.png'), url('/assets/img/home/story_bg_foot_sp@2x.png');
  }
  &__title {
    margin-bottom: min(7.46vw, 1.3em);
    .title{
      fill: $white;
      @include media-down(lg) {
        max-width: 28.8vw;
        height: auto;
      }
    }
  }

  &__body {
    font-size: calc(15 / 16 * $font-size-base);
    letter-spacing: 0.2em;
    line-height: 2.4;
    font-feature-settings: "palt";
    @include media-down(lg) {
      line-height: 1.8;
      letter-spacing: 0.16em;
      text-align: left;
    }
  }
  &__lead {
    display: block;
    font-size: min(5.33vw, calc(24 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    line-height: 2;
    letter-spacing: 0.2em;
    margin-bottom: 0.8em;
    @include media-down(lg) {
      text-align: center;
      line-height: 1.8;
    }
  }

  &__btnwrap {
    margin-top: 3em;
    .btn{
      width: 452px;
      max-width: 100%;
      font-weight: $font-weight-normal;
      font-size: calc(15 / 16 * $font-size-base);
      letter-spacing: 0.2em;
      font-feature-settings: "palt";
      padding: 1em;
    }
    .btn-white{
      &:hover,&:focus{
        background-color: $primary;
        color: $white;
      }
    }
  }
}

.block-lemoncake {
  &__title {
    @include c-block-title();
    .title{
      @include media-down(lg) {
        max-width: 58.6vw;
        height: auto;
      }
    }
  }
  &__features {
    overflow: hidden;
    @include media-up(lg) {
      // margin-bottom: 200px;
    }
  }
  &__feature {
    display: flex;
    align-items: center;
    max-width: 1365px;
    margin: 0 auto map-get($spacers, 6 );
    @include media-down(lg) {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: map-get($spacers, 5 );
    }
    
    &-img {
      flex-shrink: 0;
      position: relative;
      @include media-up(lg) {
        max-width: min(600px, 50vw);
      }
      @include media-down(lg) {
        margin-bottom: map-get($spacers, 5 );
        padding: 0 map-get($spacers, 3 );
      }
      img{
        display: block;
        max-width: 100%;
        height: auto;
      }
      img.is-sub {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate( 30%, 40%);
        pointer-events: none;
        z-index: 1;
        max-width: 70vw;
        height: auto;
        @include media-down(lg) {
          transform: translate( 5%, 40%);
        }
      }
      .is-feature02 &{
        @include media-up(lg) {
          padding-top: 80px;
          img{
            max-width: none;
            margin-left: -105px;
          }
        }
        @include media-down(lg) {
          padding-left: 0;
          padding-right: 0;
          margin-bottom: map-get($spacers, 3);
        }
      }
    }
    &:nth-child(odd) &-img{
      @include media-up(lg) {
        margin-right: 85px;
      }
    }
    &:nth-child(even) &-img{
      @include media-up(lg) {
        margin-left: 85px;
      }
    }

    &-title {
      display: flex;
      align-items: flex-start;
      font-size: calc(24 / 16 * $font-size-base);
      letter-spacing: 0.15em;
      font-weight: $font-weight-bold;
      line-height: 1.5;
      .text{
        display: flex;
        flex-direction: column;
        margin-left: 1em;
      }
      small{
        font-size: 0.625em;
        letter-spacing: 0.15em;
        color: $secondary;
      }
    }

    &-body {
      font-size: calc(15 / 16 * $font-size-base);
      letter-spacing: 0.1em;
      line-height: 2;
      @include media-up(lg) {
        max-width: 480px;
      }
      @include media-down(lg) {
        font-feature-settings: "palt";
        line-height: 1.8;
        padding: 0 map-get($spacers, 3);
      }
      p{
        margin-right: -0.5em;
      }
    }
  }

  &__feature:nth-child(even){
    @include media-up(lg) {
      flex-direction: row-reverse;
    }
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-up(lg) {
      margin: map-get($spacers, 6 ) 0;
    }
    img{
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

}

.block-lineup {
  padding: 61px map-get($spacers, 3 ) 55px;
  background-color: #F7CF4D;
  @include media-up(lg) {
    padding: 92px 20px 85px;
  }
  &__title {
    @include c-block-title();
    .title {
      @include media-down(lg) {
        max-width: 36.8vw;
        height: auto;
      }
    }
    span {
      @include media-down(lg) {
        font-size: calc(15 / 16 * $font-size-base);
      }
    }
  }

  &__inner {
    max-width: 960px;
    margin: 0 auto;
  }

  &__list {
    @include make-row(20px);
    @include media-down(lg) {
      --bs-gutter-x: 14px;
      --bs-gutter-y: 25px;
    }
  }
  &__item {
    @include make-col-ready();
    @include make-col(4);
    @include media-down(lg) {
      @include make-col(6);
    }
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  &__item:hover &__thumb-img,
  &__item:focus &__thumb-img {
    transform: scale(1.1);
  }

  &__thumb {
    aspect-ratio: 1/1;
    width: 100%;
    overflow: hidden;
  }

  &__thumb-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: $transition-base;
  }

  &__body {
    margin: 1em 0 0;
    font-weight: $font-weight-bold;
    font-size: $font-size-xs;
    letter-spacing: .05em;
    text-indent: .05em;
    text-align: center;
    @include media-up(lg) {
      margin: 1.5em 0 0;
      letter-spacing: .2em;
      text-indent: .2em;
    }
    .title {
      @include media-up(lg) {
        font-size: $font-size-lg;
      }
    }
    .price {
      display: block;
      margin-top: 0.25em;
      @include media-up(lg) {
        margin-top: 0.5em;
        font-size: calc(20 / 16 * $font-size-base);
      }
      small {
        @include media-up(lg) {
          font-size: $font-size-sm;
        }
      }
    }
  }
}

.block-recommend {
  margin: map-get($spacers, 7) 0;
  padding: 0 map-get($spacers, 3);
  &__title {
    @include c-block-title();
  }
  &__inner {
    max-width: 960px;
    margin: 0 auto;
  }

  &__list {
    @include make-row();
    @include media-down(lg) {
      --bs-gutter-x: 19px;
      --bs-gutter-y: #{map-get($spacers, 3)};
    }
  }
  &__item {
    @include make-col-ready();
    @include make-col(3);
    @include media-down(lg) {
      @include make-col(6);
    }
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  &__item:hover &__thumb-img,
  &__item:focus &__thumb-img {
    transform: scale(1.1);
  }

  &__thumb {
    aspect-ratio: 1;
    width: 100%;
    overflow: hidden;
    margin-bottom: 1em;
  }

  &__thumb-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: $transition-base;
  }
  
  &__body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-feature-settings: "palt";
    @include media-up(lg) {
      margin: 1.5em 0 0;
      letter-spacing: .2em;
      text-indent: .2em;
    }

    .title {
      font-size: min(3.46vw, calc(18 / 16 * $font-size-base));
      font-weight: $font-weight-bold;
      letter-spacing: 0.1em;
      margin-bottom: 0.7em;
      &:last-child{
        margin-bottom: 0;
      }
    }

    .desc {
      font-size: min(2.66vw, calc(14 / 16 * $font-size-base));
      line-height: 1.7;
      letter-spacing: 0.2em;
    }
  
    .price {
      font-weight: $font-weight-bold;
      font-size: min(3.46vw, $font-size-base);
      letter-spacing: 0.2em;
      font-feature-settings: "palt";
      margin-top: 0.8em;
      @include media-down(lg) {
        letter-spacing: 0.1em;
      }
    }
  }

  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
    .btn{
      width: 452px;
      max-width: 100%;
      font-weight: $font-weight-normal;
      font-size: calc(15 / 16 * $font-size-base);
      letter-spacing: 0.2em;
      font-feature-settings: "palt";
      padding: 1em;
    }
  }
}

//インスタ
.block-instagram {
  padding: 0 map-get($spacers, 3);
  margin: map-get($spacers, 7) 0;

  &__title {
    @include c-block-title();
  }

  &__list {
    max-width: calc(960px + 18px);
    margin: 0 auto;

    @include media-down(lg) {
      margin-left: -5px;
      margin-right: -5px;
    }

    &:after {
      display: block;
      width: 0;
      height: 0;
      content: '';
      clear: both;
    }
  }

  &__item {
    width: 20%;
    padding: 0 9px;
    margin-bottom: 18px;
    float: left;
    @include media-down(lg) {
      width: 50%;
      padding: 0 5px;
      margin-bottom: 10px;
    }

    &:first-child {
      @include media-up(lg) {
        width: 40%;
        margin-bottom: 17px;
      }
      @include media-down(lg) {
        width: 100%;
      }
    }

    &:nth-child(n+8) {
      @include media-up(lg) {
        display: none;
      }
    }
    &:nth-child(n+6) {
      @include media-down(lg) {
        display: none;
      }
    }

    a {
      position: relative;
      display: block;
      width: 100%;
      padding-top: 100%;
      overflow: hidden;

      &:hover img,
      &:focus img,
      &.is-video:hover video,
      &.is-video:focus video {
        transform: scale(1.1);
      }
    }

    img, video {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: $transition-base;
    }
    .is-video:after,
    .is-carousel_album:after {
      position: absolute;
      top: 0.7em;
      right: 0.7em;
      line-height: 1;
      font-family: 'Font Awesome 6 Free';
      color: $white;
    }

    .is-video:after {
      @extend .fa-solid;
      content: '\f03d';
    }

    .is-carousel_album:after {
      content: '\f24d';
    }
  }

  &__btnwrap {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    margin: 30px 0;

    @include media-down(lg) {
      margin-left: -5px;
      margin-right: -5px;
    }

    .button {
      height: 9.5rem;
      width: 100%;
      max-width: 420px;
      margin: 9px;
      font-family: 'Jost', $font-family-base;
      font-size: 1.5rem;
      letter-spacing: 0.18em;
      font-weight: $font-weight-medium;

      @include media-down(lg) {
        margin: 5px;
        height: 7rem;
      }

      img+span {
        margin-left: 0.8em;
      }
    }

  }
}