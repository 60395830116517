.is--topics .rwd002-list-content {
  &__item .cate{
    border-radius: 100em;
  }
}
.is--topics .rwd002-detail__category {
  border-radius: 100em;
}

.block-topics-item{
  &:hover,&:focus{
    text-decoration: none;
  }
  &:hover &__img,&:focus &__img{
    transform: scale(1.1);
  }
  &:hover .title,&:focus .title{
    text-decoration: underline;
    text-underline-offset: 0.2em;
  }
  &__thumb {
    overflow: hidden;
    margin-bottom: 1em;
  }
  &__img{
    display: block;
    max-width: 100%;
    height: auto;
    aspect-ratio: 1;
    object-fit: cover;
    transition: $transition-base;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-feature-settings: "palt";
  }

  .title,
  .desc{
    width: 100%;
  }

  .date {
    font-size: calc(14 / 16 * $font-size-base);
    font-family: 'Jost', $font-family-base;
    font-weight: 500;
    letter-spacing: 0.2em;
    @include media-down(lg) {
      display: none;
    }
  }

  .category {
    @include text-truncate;
    font-size: calc(12 / 16 * $font-size-base);
    letter-spacing: 0.1em;
    text-indent: 0.1em;
    background-color: $white;
    padding: 0.15em 1em;
    margin-left: 1em;
    border-radius: 100em;
    max-width: calc(100% - 10em);
    @include media-down(lg) {
      display: none;
    }
  }

  .title {
    font-size: min(3.46vw, calc(18 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    letter-spacing: 0.1em;
    margin-bottom: 0.7em;
    @include media-up(lg) {
      padding-top: 0.5em;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }

  .desc {
    font-size: min(2.66vw, calc(14 / 16 * $font-size-base));
    line-height: 1.7;
    letter-spacing: 0.2em;
  }
}