@keyframes up_down_line {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

@keyframes zoomUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.15);
  }
}

.rwd002-footerSocial {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  margin-bottom: 90px;
  @include media-up(lg) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  &__title{
    letter-spacing: 0.16em;
    margin-right: 1em;
    font-family: 'Lato', $font-family-sans-serif;
  }
  &__btn{
    @extend .btn;
    font-size: 1.8em;
    margin: 0 0.1em;
    line-height: 1;
    transition: $transition-base;
  }
  &__btn.is-fb{
    &:hover,&:focus{
      color: $sns-facebook-bg;
    }
  }
  &__btn.is-tw{
    &:hover,&:focus{
      color: $sns-twitter-bg;
    }
  }
  &__btn.is-line{
    &:hover,&:focus{
      color: $sns-line-bg;
    }
  }
}

.rwd002-pagetop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -50px;
  width: 100px;
  height: 100px;
  font-size: $font-size-xs;
  font-family: "Roboto", $font-family-sans-serif;
  color: $body-color;
  text-decoration: none;
  letter-spacing: 0.1em;
  transform-origin: bottom;
  border: 1px solid $body-color;
  background-color: $white;

  @include media-up(lg) {
    right: map-get($spacers, 5);
  }
  @include media-down(lg) {
    right: calc(50% - 50px);
  }
  &:before {
    content: "";
    display: block;
    margin-bottom: 1em;
    width: 34px;
    height: 21px;
    background: svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.67 21.05"><g fill="none" stroke="#{$dark}" stroke-miterlimit="10"><path d="m.34 20.68 16.5-15 16.5 15"/><path d="m.34 15.65 16.5-15 16.5 15"/></g></svg>'
      )
      no-repeat center / contain;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    &:before {
      animation: up_down_line 0.9s infinite ease-in-out;
    }
  }
  &__wrap {
    width: 100%;
    z-index: 10;
    transition: $transition-base;
    @include media-up(lg) {
      position: fixed;
      position: sticky;
      bottom: 80px;
      margin: 0 auto;
      padding-left: map-get($spacers, 1);
      padding-right: map-get($spacers, 1);
    }
    @include media-down(lg) {
      position: relative;
    }
    &.hidden {
      transform: translateY(200px);
      opacity: 0;
    }
  }
}

.rwd002-footer {
  @include media-up(md) {
    padding: map-get($spacers, 5) map-get($spacers, 3) map-get($spacers, 3);
  }
  @include media-down(lg) {
    padding-top: 90px;
  }
  @include media-down(md) {
    padding: map-get($spacers, 3) map-get($spacers, 3);
  }

  &__inner {
    display: flex;
    align-items: flex-start;
    max-width: 960px;
    margin: 0 auto;
    @include media-down(lg) {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: map-get($spacers, 4 );
    }
  }

  &__logo {
    @include media-up(lg) {
      margin-right: map-get($spacers, 4);
    }
    @include media-down(lg) {
      margin: 0 auto map-get($spacers, 3);
    }
  }

  &__info {
    @include media-up(lg) {
      flex-grow: 1;
      padding-right: map-get($spacers, 4);
      margin-bottom: map-get($spacers, 4);
    }
    @include media-down(lg) {
      margin-bottom: 2em;
      text-align: center;
    }
  }
  &__companyName {
    font-size: calc(18 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.2em;
    font-feature-settings: "palt";
  }

  &__address {
    font-size: calc(15 / 16 * $font-size-base);
    letter-spacing: 0.15em;
    font-feature-settings: "palt";
    @include media-up(lg) {
      margin-right: -0.5em;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .tel {
    font-size: calc(30 / 16 * $font-size-base);
    font-family: 'Roboto Condensed', $font-family-base;
    letter-spacing: 0.16em;
    font-weight: $font-weight-bold;
    font-feature-settings: "palt";
  }

  .opentime {
    font-size: calc(15 / 16 * $font-size-base);
    letter-spacing: 0.15em;
    line-height: 1.73;
    font-feature-settings: "palt";
    &:last-child{
      margin-bottom: 0;
    }
  }

  &__copyright,
  &__login {
    display: block;
    text-align: center;
    font-size: $font-size-xxs;
    letter-spacing: 0.14em;
    text-indent: 0.14em;
    font-feature-settings: "palt";
  }

  &__copyright {
    @include media-down(md) {
      margin-left: -1.3em;
      margin-right: -1.3em;
    }
  }

  &__login {
    margin-top: 1em;
  }
}

.rwd002-footerNav {
  @include media-up(lg) {
    flex-basis: 33%;
  }
  &__list,
  &__sublist {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__list {
    font-size: min(4vw, $font-size-base);
    font-family: 'Roboto Condensed', $font-family-base;
    column-count: 2;
    column-gap: 1em;
    @include media-down(md) {
      column-gap: 0;
      flex-grow: 1;
      margin-right: 0;
    }
  }
}

.rwd002-footerNav-item {
  display: flex;
  flex-wrap: wrap;
  break-inside: avoid;

  &__link {
    position: relative;
    display: inline-block;
    padding: 0.3em 0 0.3em 0.7em;
    letter-spacing: 0.1em;
    font-feature-settings: "palt";
    &:hover,
    &:focus {
      text-decoration: underline;
      text-underline-offset: 0.2em;
    }
    &:before {
      position: absolute;
      content: "";
      height: 1px;
      width: 0.3em;
      top: 1em;
      left: 0;
      background-color: $primary;
    }
  }
}

.rwd002-footerNav-subitem {
  &__link {
    display: inline-block;
    padding: 0.3em 0;
    margin-bottom: 0.5em;
    letter-spacing: 0.16em;
    font-weight: $font-weight-bold;
    transition: $transition-base;
    &:hover,
    &:focus {
      color: $white;
    }
    @include media-down(lg) {
      margin-bottom: 0;
      margin-right: 2em;
    }
  }
}

.rwd002-footer-subnav {
  @include media-up(md) {
    display: none;
  }
  &__tgl {
    @extend .btn;

    &:focus {
      box-shadow: none;
    }

    @include media-up(md) {
      display: none;
    }
    @include media-down(md) {
      position: relative;
      flex-basis: 40px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(50% - 5px);
        width: 10px;
        height: 1px;
        background-color: darken($white, 50%);
        transition: transform 0.2s ease-in-out;
      }

      &:after {
        transform: rotate(-90deg);
      }

      &.is-active {
        &:before {
          transform: rotate(180deg);
        }
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }
  &__list {
    list-style: none;
    @include media-down(md) {
      padding-left: 1.7em;
      padding-bottom: 1em;
    }
  }
}

.rwd002-footer-subnav-item {
  &__link {
    @include media-down(md) {
      display: block;
      padding: 0.2em 0;
    }
  }
}

.rwd002-footer-search {
  margin-top: 2em;
  @include media-down(lg) {
    width: 100%;
    margin-top: 1.5em;
    margin-bottom: 3em;
  }
  &__inner {
    position: relative;
    max-width: 320px;
    @include media-down(md) {
      margin-left: -0.8em;
      margin-right: -0.8em;
    }
  }
  &__input {
    @extend .form-control;
    padding-right: 2.2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background-color: tint($dark, 10%);
    transition: $transition-base;
    border-width: 0;
  }
  &__btn {
    @extend .btn;
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(100% - 2.2em);
    width: 2.2em;
    padding: 0 0.5em;
    pointer-events: none;
    transition: $transition-base;
    > svg {
      width: 1em;
      height: 100%;
      fill: tint($dark, 30%);
    }
  }
  &__btn:focus,
  &__input:focus + &__btn {
    pointer-events: auto;
    right: 0;
  }
}

.footer-head {
  background-color: #EFE5D1;
  padding: map-get($spacers, 7) map-get($spacers, 3) map-get($spacers, 6);
  overflow: hidden;
  @include media-down(lg) {
    padding: map-get($spacers, 4) map-get($spacers, 3);
  }
}

.block-shop {
  max-width: 960px;
  margin: 0 auto map-get($spacers, 6);
  &:last-child {
    margin-bottom: 0;
  }
  &__title {
    @include c-block-title();
    margin-bottom: 1em;
  }
  &__body {
    display: flex;
    align-items: stretch;
    margin-bottom: map-get($spacers, 5);
    @include media-down(lg) {
      flex-direction: column;
    }
  }
  &__img {
    @include media-up(lg) {
      margin-right: 56px;
    }
    @include media-down(lg) {
      margin-bottom: 1em;
    }
    img{
      display: block;
      object-fit: cover;
      max-width: 100%;
      height: auto;
      @include media-down(lg) {
        aspect-ratio: 1;
      }
    }
  }
  &__info {
    flex-grow: 1;
  }

  &__table {
    @include media-down(lg) {
      margin-right: -1em;
    }
    thead th{
      font-size: calc(18 / 16 * $font-size-base);
      letter-spacing: 0.2em;
    }
    th,td {
      vertical-align: top;
      letter-spacing: 0.15em;
      font-size: min(3.733vw, calc(15 / 16 * $font-size-base));
      padding: 0.5em 0;
      font-feature-settings: "palt";
      @include media-down(lg) {
        letter-spacing: 0.05em;
      }
    }
    th{
      white-space: nowrap;
      &:first-child {
        padding-left: 0;
        padding-right: min(1.5em, 2.933vw);
      }
      &:last-child {
        padding-right: 0;
      }
    }
    a[href^="tel"]{
      @include media-up(lg) {
        pointer-events: none;
      }
    }
  }

  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: map-get($spacers, 3);
    @include media-down(lg) {
      justify-content: center;
    }
    .btn {
      width: 95%;
      padding: 1em;
      font-weight: $font-weight-normal;
      letter-spacing: 0.2em;
      font-size: calc(14 / 16 * $font-size-base);
      @include media-up(lg) {
        max-width: 245px;
      }
    }
    .btn-outline-primary {
      background-color: $white;
      &:hover,&:focus{
        background-color: $primary;
      }
    }
    .fa-arrow-up-right-from-square{
      margin-left: 1em;
    }
  }

  &__foot {}

}

.block-shop-eatin {
  position: relative;
  background-color: $white;
  padding: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-feature-settings: "palt";
  z-index: 0;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 115px;
    height: 30px;
    background-color: #EFE5D1;
    border-top: 1px solid $primary;
    transform-origin: 50% 100%;
    z-index: -1;
  }
  &:before {
    bottom: 100%;
    left: 0;
    transform: translateX(-50%) rotate(135deg);
  }
  &:after {
    bottom: 0;
    right: 0;
    transform: translateX(50%) rotate(-45deg);
  }
  @include media-down(lg) {
    flex-direction: column-reverse;
    // align-items: center;
    padding-top: min(75px, 20vw);
    margin-top: 170px;
    @include media-down(lg) {
      margin-top: 195px;
    }
  }

  &__img {
    display: block;
    max-width: 100%;
    height: auto;
    @include media-up(lg) {
      margin-right: 2em;
    }
    @include media-down(lg) {
      margin-top: 1em;
      width: 100%;
    }
  }

  &__title {
    font-size: calc(20 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.15em;
  }

  &__body{
    font-size: calc(15 / 16 * $font-size-base);
    line-height: 1.6;
    letter-spacing: 0.15em;
    p {
      margin-bottom: 0;
    }
  }

  &__kakigori {
    position: absolute;
    @include media-up(lg) {
      top: 0;
      right: -40px;
      max-width: 40vw;
      transform: translateY( -50%);
    }
    @include media-down(lg) {
      height: auto;
      max-width: 100vw;
      bottom: max(calc(100% - 70px), calc(100% - 19vw));
      left: 50%;
      transform: translateX( -50%);
    }
  }
}

.block-topics {
  max-width: 960px;
  margin: 0 auto;
  &__title {
    @include c-block-title();
  }

  &__list {
    @include make-row( map-get($spacers, 3) );
    @include media-down(lg) {
      --#{$variable-prefix}gutter-y: #{map-get($spacers, 3)};
      --#{$variable-prefix}gutter-x: #{map-get($spacers, 2)};
    }
  }

  &-item {
    @include make-col-ready();
    @include media-up(lg) {
      @include make-col(3);
    }
    @include media-down(lg) {
      @include make-col(6);
    }
  }
  
  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
    .btn{
      width: 452px;
      max-width: 100%;
      font-weight: $font-weight-normal;
      font-size: calc(15 / 16 * $font-size-base);
      letter-spacing: 0.2em;
      font-feature-settings: "palt";
      padding: 1em;
    }
  }
}