.is--lineup{
  .rwd002-detail__image {
    width: min(100%, 900px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: map-get($spacers, 4);
    @include media-down(lg) {
      margin-bottom: map-get($spacers, 3);
    }
  }

}

.lineup-list {
  &__title {
    @include c-block-title();
  }
  &__item {
    .cate {
      color: $primary;
      border-radius: 100em;
    }
    .price {
      color: $body-color;
      font-weight: $font-weight-bold;
      letter-spacing: 0.15em;
      font-size: min(4.26vw, calc(20 / 16 * $font-size-base));
    }
    .tags {
      margin-top: 0.8em;
      font-size: min(3.2vw, calc(14 / 16 * $font-size-base));
      color: $secondary;
      letter-spacing: 0.1em;
      font-feature-settings: "palt";
    }
    .tag {
      display: inline-block;
      margin-right: 0.8em;
    }
  }
}


.lineup-detail {
  max-width: 900px;
  margin: 0 auto;
}

.lineup-detail-head {
  max-width: 960px;
  margin: 0 auto map-get($spacers, 5);
  &__subject {
    text-align: center;
    font-size: calc(24 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.15em;
    text-indent: 0.15em;
    @include media-down(lg) {
      text-indent: 0;
    }
  }

  &__desc {
    text-align: center;
    font-weight: $font-weight-bold;
    font-size: calc(15 / 16 * $font-size-base);
    letter-spacing: 0.2em;
    line-height: 1.8;
  }

  &__price {
    text-align: center;
    font-size: calc(24 / 16 * $font-size-base);
    letter-spacing: 0.15em;
    font-weight: $font-weight-bold;
  }
}

.lineup-detail-keywords {
  margin-bottom: map-get($spacers, 3);
  &__title {
    text-align: center;
    font-size: min(4.8vw, calc(22 / 16 * $font-size-base));
    letter-spacing: 0.18em;
    text-indent: 0.18em;
    margin-bottom: 1em;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    padding: 0;
    margin-top: -0.5em;
    font-size: min(3.46vw, calc(15 / 16 * $font-size-base));
    @include media-down(lg) {
      justify-content: center;
    }
  }

  .tag {
    display: inline-block;
    color: $secondary;
    padding: 0.5em;
    margin: 0.5em 0.2em 0;
    letter-spacing: 0.1em;
    text-indent: 0.1em;
    transition: $transition-base;
    font-size: calc(14 / 16 * $font-size-base);
    &:hover,
    &:focus {
      text-decoration: underline;
      text-underline-offset: 0.2em;
    }
  }
}

.lineup-detail-lead {
  display: flex;
  justify-content: center;
  line-height: 2;
  letter-spacing: 0.2em;
  font-feature-settings: "palt";
}

.lineup-detail-table {
  width: 100%;
  max-width: 800px;
  margin: map-get($spacers, 6) auto;
  border-width: 0;
  th,td {
    border: solid #c9c9c9;
    border-width: 1px 0;
    padding: 0.5em;
    letter-spacing: 0.2em;
    font-feature-settings: "palt";
    @include media-up(lg) {
      padding: 0.5em 1.5em;
    }
  }
}

.lineup-detail-gallery {
  max-width: 900px;
  margin: map-get($spacers, 6) auto;
  .row {
    @include media-up(lg) {
      --#{$variable-prefix}gutter-x: 40px;
      --#{$variable-prefix}gutter-y: 48px;
    }
    @include media-down(lg) {
      --#{$variable-prefix}gutter-x: #{map-get($spacers, 3)};
      --#{$variable-prefix}gutter-y: #{map-get($spacers, 3)};
    }
  }
  [class*="col-"] > img{
    display: block;
    max-width: 100%;
    height: auto;
  }
}