.rwd002-taglist{
  margin-top: map-get($spacers, 6);
  margin-bottom: map-get($spacers, 6);
  padding-left: map-get($spacers, 3);
  padding-right: map-get($spacers, 3);
  @include media-down(lg) {
    margin-top: map-get($spacers, 4);
    margin-bottom: map-get($spacers, 4);
  }

  .is-main &{
    @include media-up(xl) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  
  &__cats,
  &__tags {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    font-feature-settings: "palt";
  }

  &__cats {
    flex-wrap: wrap;
    margin-top: -1em;
  }

  &__catBtn {
    @extend .btn;
    @extend .btn-outline-primary;
    @extend .btn-pill;
    padding-left: 1.2em;
    padding-right: 1.2em;
    margin: 1em 0.8em 0;
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    letter-spacing: 0.2em;
    text-indent: 0.2em;
    font-feature-settings: "palt";
    @include media-down(md) {
      margin: 1em 0.5em 0;
      padding: 0.3em 0.7em;
      letter-spacing: 0.1em;
      text-indent: 0.1em;
    }
    &.current {
      @extend .btn-primary;
    }
  }

  &__tags {
    flex-direction: column;
    margin-top: 1em;
    &:first-child{
      margin-top: 0;
    }
  }

  &__list {
    text-align: center;
  }

  &__btnwrap {
    margin-top: 0.4em;
    &:first-child{
      margin-top: 0;
    }
  }

  &__tagBtn {
    @extend .btn;
    @extend .btn-sm;
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
    color: $secondary;
    letter-spacing: 0.14em;
    @include media-down(lg) {
      font-size: $font-size-xs;
    }
    &.current {
      font-weight: $font-weight-bold;
      text-decoration: underline;
      text-underline-offset: 0.2em
    }
  }

  &__tglBtn {
    @extend .btn;
    @extend .btn-sm;
    &:focus{
      box-shadow: none;
    }
    font-size: $font-size-base * .8125;
  }
}