.content-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 142px;
  padding-top: 10px;
  background: 50%/cover no-repeat;
  background-color: $secondary;
  @include media-up(lg) {
    padding-top: 30px;
    height: 306px;
  }

  .is--lineup & {
    background-image: url('/assets/img/cont-header_lineup_sp@2x.jpg');
    @include media-up(lg) { background-image: url('/assets/img/cont-header_lineup@2x.jpg'); }
    
  }
  .is--story & {
    background-image: url('/assets/img/cont-header_story_sp@2x.jpg');
    @include media-up(lg) { background-image: url('/assets/img/cont-header_story@2x.jpg'); }
    
    &__title .title {
      @include media-down(lg) {
        max-width: 28.8vw;
        height: auto;
      }
    }
  }

  &__title {
    @include c-block-title($white);
    margin-bottom: 0;
    .title {
      @include media-down(lg) {
        max-width: 36.8vw;
        height: auto;
      }
    }
    span {
      margin-top: .8em;
      font-size: $font-size-lg;
      text-indent: 0.2em;
      @include media-down(lg) {
        margin-top: .6em;
        font-size: $font-size-sm;
      }
    }
  }
}