.is--story {
  .rwd002-breadcrumbs {
    @include media-down(lg) {
      display: none;
    }
  }
}

.story-concept {
  position: relative;
  margin: 40px auto 30px;
  padding: 0 min(7.3%, 27px);
  z-index: 0;
  @include media-up(lg) {
    margin: 20px auto 84px;
    padding: 0 20px;

  }
  &__body {
    max-width: 100%;
    width: 960px;
    margin: 0 auto;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    letter-spacing: 0.2em;
    line-height: 2.5;
    text-align: center;
    font-feature-settings: "palt";
    @include media-down(lg) {
      font-size: $font-size-sm;
      line-height: 2;
      letter-spacing: 0.15em;
      text-align: left;
    }
    p {
      @include media-up(lg) {
        margin-bottom: 0;
      }
    }
  }
  &__title {
    display: block;
    font-size: min(5.33vw, calc(24 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    line-height: 2;
    letter-spacing: 0.15em;
    text-indent: 0.15em;
    margin-bottom: 0.8em;
    @include media-down(lg) {
      line-height: 1.7;
      text-align: center;
    }
  }

  &__bg {
    margin: 0 auto;
    text-align: center;
    @include media-up(lg) {
      position: absolute;
      left: -200px;
      right: -200px;
      top: 45px;
      width: 1026px;
      height: 437px;
      z-index: -1;
    }
    img {
      @include media-down(lg) {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.story-project {
  position: relative;
  padding-bottom: 58px;
  @include media-up(lg) {
    padding-bottom: 137px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #F7CF4D;
    z-index: -1;
    @include media-up(lg) {
      height: calc(100% - 283px);
    }
  }

  &__mainimg {
    margin-bottom: 30px;
    text-align: center;
    @include media-up(lg) {
      margin-bottom: 54px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__inner {
    max-width: 100%;
    width: 960px;
    margin: 0 auto;
    @include media-down(lg) {
      padding: 0 min(7.3%, 27px);
    }
  }

  &__body {
    margin-bottom: 63px;
    font-size: calc(15 / 16 * $font-size-base);
    letter-spacing: 0.2em;
    line-height: 2.4;
    text-align: center;
    font-feature-settings: "palt";
    @include media-down(lg) {
      margin-bottom: 33px;
      line-height: 1.8;
      letter-spacing: 0.16em;
      text-align: left;
    }
  }
  &__lead {
    display: block;
    font-size: min(5.33vw, calc(24 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    line-height: 2;
    letter-spacing: 0.2em;
    margin-bottom: 0.8em;
    @include media-down(lg) {
      text-align: center;
      line-height: 1.8;
    }
  }

  &__process {
    margin: 0 auto 40px;
    @include media-up(lg) {
      margin: 0 auto 75px;
    }
    text-align: center;
    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__effort {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 100%;
    width: 960px;
    margin: 27px auto 40px;
    padding: 34px 36px;
    background-color: $white;
    @include media-up(lg) {
      display: grid;
      align-items: flex-start;
      grid-template-columns: 286px auto;
      grid-template-rows: auto auto;
      gap: 0px 28px;
      grid-auto-flow: row;
      grid-template-areas:
        "is-img is-title"
        "is-img is-body";
      margin: 75px auto 70px;
    }
    @include media-down(lg) {
      align-items: center;
      flex-direction: column;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: calc(100% - 30px);
      right: -4px;
      width: 109px;
      height: 75px;
      background: url('/assets/img/story/illust_bird@2x.png') 50%/contain no-repeat;
      @include media-up(lg) {
        bottom: calc(100% - 28px);
        right: 20px;
      }
    }
    .is-img { 
      margin-bottom: 1em;
      @include media-up(lg) {
        grid-area: is-img;
        margin: 0;
      }
      @include media-down(lg) {
        width: 100%;
      }
      img {
        max-width: 100%;
        height: auto;
        @include media-down(lg) {
          width: 100%;
        }
      }
    }
    .is-title {
      display: block;
      margin-bottom: 0.5em;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
      line-height: 1.7;
      letter-spacing: 0.15em;
      @include media-up(lg) {
        grid-area: is-title;
        font-size: min(5.33vw, calc(22 / 16 * $font-size-base));
        margin-bottom: 0.25em;
      }
      @include media-down(lg) {
        text-align: center;
        line-height: 1.8;
      }
    }

    .is-body {
      font-size: calc(15 / 16 * $font-size-base);
      line-height: 1.8;
      letter-spacing: .08em;
      font-feature-settings: "palt";
      @include media-up(lg) {
        grid-area: is-body;
        line-height: 2;
        letter-spacing: 0.1em;
      }
      p {
        margin: 0;
      }
      p + p {
        margin-top: 1rem;
      }
    }
  }

  &__sdgs {
    margin: 44px auto 0;
    text-align: center;
    @include media-up(lg) {
      margin: 66px auto 0;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.story-cooking-head {
  @include media-down(lg) {
    margin-top: -26px;
    padding: 0 min(7.3%, 27px) 43px;
  }
  &__row {
    display: flex;
    max-width: 100%;
    @include media-up(lg) {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row-reverse;
      width: 1366px;
      margin: 0 auto;
      padding-right: 18px;
    }
    @media screen and (min-width: 900px) and (max-width: 1200px) {
      width: 100%;
      padding-left: 2.375rem;
    }
    @include media-down(lg) {
      flex-direction: column;
    }
  }

  &__img {
    margin: 0;
    @include media-up(lg) {
      width: min(40.70278184vw, 556px);
      margin-top: -88px;
    }
    @include media-down(lg) {
      text-align: center;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__section + &__section {
    margin-top: 33px;
    @include media-up(lg) {
      margin-top: 66px;
    }
  }

  &__body {
    max-width: 100%;
    width: 100%;
    font-size: $font-size-base;
    line-height: 1.86;
    letter-spacing: .1em;
    font-feature-settings: "palt";
    @include media-up(lg) {
      width: 580px;
      padding: 112px min(3.953147877vw,54px) 90px 0;
      font-size: calc(15 / 16 * $font-size-base);
    }
    p{
      @include media-up(lg) {
        margin-right: -1em;
      }
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: calc(24 / 16 * $font-size-base);
    line-height: 1.75;
    letter-spacing: .15em;
    font-weight: $font-weight-bold;
    @include media-down(lg) {
      font-size: min(5.5vw, calc(21 / 16 * $font-size-base));
    }
  }

  &__graph {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }

}

.story-cooking {
  &__graph {
    width: 100%;
    margin: 0 auto 36px;
    @include media-up(lg) {
      margin: 0 auto 85px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

.story-point {
  max-width: 100%;
  width: 1000px;
  margin: 36px auto 48px;
  font-feature-settings: "palt";
  @include media-up(lg) {
    margin: 187px auto 92px;
    padding: 0 20px;
  }
  @include media-down(lg) {
    padding: 0 min(7.3%, 27px);
    overflow: hidden;
  }
  &__row {
    @include make-row(106px);
    @include media-down(lg) {
      --bs-gutter-x: 0;
      --bs-gutter-y: #{map-get($spacers, 4)};
    }
  }

  &__item {
    @include make-col-ready();
    @include make-col(12);
    @include media-up(lg) {
      @include make-col(6);
    }
    position: relative;
    z-index: 0;
    &:nth-child(odd) {
      @include media-up(lg) {
        margin-top: -102px;
      }
    }
  }

  &__img {
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  &__body {
    margin-top: 2em;
    font-size: calc(15 / 16 * $font-size-base);
    letter-spacing: 0.1em;
    line-height: 1.86;
    @include media-down(lg) {
      position: relative;
    }
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: calc(21 / 16 * $font-size-base);
    line-height: 1.75;
    letter-spacing: .13em;
    @include media-up(lg) {
      font-size: calc(24 / 16 * $font-size-base);
      letter-spacing: .15em;
      white-space: nowrap;
    }
  }

  &__lemonpeel {
    position: absolute;
    right: -137px;
    bottom: 100%;
    z-index: -1;
    @include media-up(lg) {
      right: calc(100% - 251px);
      bottom: -51px;
    }
    @include media-down(lg) {
      width: 310px;
      z-index: 1;
    }
    img {
      @include media-down(lg) {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.story-review {
  margin: 50px auto 58px;
  @include media-up(lg) {
    margin: 95px auto 100px;
  }
  &__row {
    display: flex;
    max-width: 100%;
    width: 960px;
    margin: 0 auto;
    padding: 42px min(7.3%, 27px) 55px;
    background-color: #EFE5D1;
    @include media-up(lg) {
      height: 411px;
      padding: 0 50px 0 75px;
    }
    @include media-down(lg) {
      justify-content: flex-end;
      flex-direction: column-reverse;
    }
  }

  &__comment {
    position: relative;
    max-width: 100%;
    width: 100%;
    height: 460px;
    @include media-up(lg) {
      flex-shrink: 0;
      margin-top: -49px;
      width: 350px;
      height: calc(100% + 50px);
    }
    @include media-down(lg) {
      text-align: center;
    }
  }
  iframe {
    max-width: 100%;
    height: 460px;
    @include media-up(lg) {
      height: 100%;
    }
  }

  &__body {
    font-size: calc(15 / 16 * $font-size-base);
    letter-spacing: 0.1em;
    line-height: 1.86;
    font-feature-settings: "palt";
    @include media-up(lg) {
      padding-left: 46px;
      align-self: center;
    }
    .is-title {
      font-weight: $font-weight-bold;
      font-size: calc(21 / 16 * $font-size-base);
      line-height: 1.75;
      letter-spacing: .15em;
      @include media-up(lg) {
        font-size: calc(24 / 16 * $font-size-base);
        white-space: nowrap;
      }
      @include media-down(lg) {
        text-align: center;
      }
    }
  }

}

.story-product {
  margin-bottom: 36px;
  font-feature-settings: "palt";
  @include media-up(lg) {
    margin-bottom: 100px;
  }
  &__mainimg {
    margin: 0;
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  &__row {
    display: flex;
    max-width: 100%;
    width: 1140px;
    margin: 0 auto;
    padding: 0 min(7.3%, 27px);
    @include media-up(lg) {
      gap: 48px;
      padding: 0 20px;
    }
    @include media-down(lg) {
      margin-top: -50px;
      flex-direction: column;
    }
  }

  &__img {
    @include media-up(lg) {
      align-self: flex-start;
      margin-top: -101px;
      margin-bottom: 0;
    }
    @include media-down(lg) {
      max-width: 100%;
      width: 83.18%;
      height: auto;
      margin-right: auto;
      margin-bottom: 24px;
    }
    img {
      @include media-down(lg) {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
  }

  &__body {
    font-size: calc(15 / 16 * $font-size-base);
    letter-spacing: 0.05em;
    line-height: 1.86;
    @include media-up(lg) {
      padding-left: 46px;
      align-self: flex-end;
      letter-spacing: 0.1em;
    }
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: calc(21 / 16 * $font-size-base);
    line-height: 1.75;
    letter-spacing: .15em;
    text-align: center;
    @include media-up(lg) {
      font-size: calc(24 / 16 * $font-size-base);
      white-space: nowrap;
      text-align: left;
    }
  }
}