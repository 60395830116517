@mixin c-module-title() {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.75em;
  font-weight: normal;
  letter-spacing: 0.22em;
  text-indent: 0.22em;
  font-size: $h2-font-size;
  @include media-down(lg) {
    font-size: 1.875rem;
  }
  @include media-down(md) {
    font-size: $h4-font-size;
  }

  span {
    font-family: "Roboto", $font-family-sans-serif;
    font-weight: $font-weight-normal;
  }

  &:after{
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    width: 82px;
    height: 1px;
    margin: auto;
    background: $primary;
  }
}

@mixin c-block-title($theme: $primary) {
  color: $theme;
  fill: $theme;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1.4em;
  .title {
    height: min(33px, 6.4vw);
  }
  span,a{
    font-weight: $font-weight-bold;
    font-size: min(4vw, $font-size-base);
    letter-spacing: 0.2em;
    margin-top: 1em;
  }
  &.is-horizontal {
    align-items: baseline;
    justify-content: flex-start;
    flex-direction: row;
    span,a{
      margin-left: 2em;
      margin-top: 0;
      @include media-down(lg) {
        margin-left: 1.5em;
      }
    }
    .title {
      @include media-down(lg) {
        width: auto;
      }
    }
  }
}